@import 'shared/styles/colors.less';
@import 'shared/styles/breakpoints.less';

.header {
    padding: 0 24px;
    background-color: white;
}

.buttons_divider {
    height: 23px;
    display: inline-block;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid #e9e9e9;

    @media (min-width: @screen-md-min) {
        margin: 0 24px;
    }
}

.right_panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.headerBtn {
    width: 32px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    @media (min-width: @screen-xs-min) {
        width: auto;
        justify-content: flex-start;
    }

    .headerBtn_avatar {
        display: block;
        width: 100%;
        height: 32px;
        border-radius: 100%;
        background-color: @primary-color;
        font-size: 16px;
        line-height: 36px;
        color: white;

        @media (min-width: @screen-xs-min) {
            width: 32px;
            height: 32px;
        }
    }

    .headerBtn_label {
        font-size: 16px;
        line-height: 1.5;
        margin-left: 8px;
        margin-right: 20px;

        @media (min-width: @screen-sm-min) {
            margin-right: 30px;
        }
    }

    .headerBtn_label,
    .headerBtn_caret {
        display: none;

        @media (min-width: @screen-xs-min) {
            display: block;
            margin-left: 10px;
        }
    }
}

.lang_menu {
    position: relative;
    width: 160px;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .lang_menuItem {
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.3s;

        button {
            width: 100%;
            padding: 13px 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: transparent;
            border: none;
            cursor: pointer;

            span:first-of-type {
                font-size: 16px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 12px;
                }
            }

            span:last-of-type {
                color: @primary-color;
                display: none;
            }
        }



        &[data-active="true"] {
            span:last-of-type {
                display: inline;
            }
        }

        &:hover {
            background: #f7fcfe;
        }
    }
}

.user_menu {
    position: relative;
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    a,
    button {
        display: flex;
        align-items: center;
        width: 100%;
        color: rgba(0, 0, 0, 0.65);
        font-weight: normal;
        font-size: 14px;
        white-space: nowrap;
        transition: all 0.3s;
        background: transparent;
        border: none;
        padding: 13px 18px;
        margin: 0;
        outline: none;
        cursor: pointer;

        span:first-of-type {
            margin-right: 15px;
        }

        &:hover {
            background: #f7fcfe;
            color: @link-hover-color;
        }
    }

}


