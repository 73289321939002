@import 'shared/styles/breakpoints.less';

.infoWrapper {
    & > div {
        width: 100%;
        margin-bottom: 10px;


        h3 {
            margin: 0;
            font-size: 16px;
        }

        span {
            margin: 0;
        }

        @media (min-width: @screen-lg-min) {
            span {
                font-size: 20px;
                line-height: 1.5;
            }

            h3 {
                font-size: 18px;
            }
        }
    }
}
