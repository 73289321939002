@import 'shared/styles/breakpoints.less';

.tableWrapper {
    @media (min-width: @screen-lg-min) {
        margin: 0 auto;
        width: 90%;
    }
    @media (min-width: @screen-xl-min) {
        width: 75%;
    }
    @media (min-width: @screen-xxl-min) {
        width: 60%;
    }
}

.actions {
    button + button {
        margin-left: 20px;
    }
}
