@import 'shared/styles/breakpoints.less';

.actions {
    button + button {
        margin-left: 20px;
    }
}

.titleActions {
    button {
        & span:last-of-type {
            display: none;

            @media (min-width: @screen-sm-min) {
                display: inline;
            }
        }
    }
}
