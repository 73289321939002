@import 'shared/styles/breakpoints.less';

.center {
    height: 100%;

    & > div:last-of-type {
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.extra {
    button {
        display: block;
        margin: 20px auto 0;
    }
}

.resultTitle {
    font-size: 16px;
    font-weight: bold;

    @media (min-width: @screen-xs-min) {
        font-size: 20px;
    }

    @media (min-width: @screen-md-min) {
        font-size: 24px;
    }
}
