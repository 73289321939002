@import 'shared/styles/breakpoints.less';
@import 'shared/styles/colors.less';

.content {
    min-height: calc(100vh - 64px - 69px - 24px);
    background: white;

    @media (min-width: @screen-xs-min) {
        margin: 24px 24px 0;
    }
}

.sider_wrapper {
    height: 100%;
    position: relative;

    & > span {
        position: absolute;
        left: 0;
        bottom: 50px;
        color: red;
        width: 100%;
        text-align: center;
    }

    :global {
        .ant-menu-item {
            color: @primary-color-light;

            a {
                color: @primary-color-light;
            }
        }

        .ant-menu-vertical-left .ant-menu-item:first-of-type {
            margin-top: 0;
        }
    }
}



.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 64px;
    margin: 0 auto 0 24px;
    padding: 10px 0;

    img {
        display: block;
        max-width: 100%;
        max-height: 64px;
    }

}
