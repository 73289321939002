.progressBar {
    & > div {
        & > div {
            div {
                height: 15px !important;
                background-color: red;
            }
        }


    }


}
