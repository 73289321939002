@import 'shared/styles/colors.less';
@import 'shared/styles/breakpoints.less';

.wrapper {
    width: 80%;
    margin: 0 auto;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;

    & > span {
        font-size: 35px;
    }

    & > h3 {
        margin: 0;
        margin-left: 20px;
    }
}

.uploadButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploadComponent {

    svg {
        color: @primary-color;
        fill: @primary-color;



        path:last-of-type {
            fill: @primary-color;
        }
    }
}
