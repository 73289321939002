@import 'shared/styles/breakpoints.less';

.wrapper {
    height: 100%;

    & > div:last-of-type {
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.form {
    button {
        width: 100%;
    }
}


.card {
    width: 100%;
    border-width: 0;

    & > div {
        padding: 12px;
    }



    @media (min-width: @screen-xs-min) {
        width: 300px;
        border-width: 1px;

        & > div {
            padding: 24px;
        }
    }

    @media (min-width: @screen-lg-min) {
        width: 500px;

        :global .ant-card-head-title {
            padding: 0;
        }
    }
}

.cardTitle {
    width: 100%;
    display: flex;
    align-items: center;

    & > span {
        font-size: 20px;
    }

    & > h3 {
        margin: 0;
        margin-left: 20px;
        white-space: break-spaces;
        word-break: break-word;
        font-size: 12px;
    }

    @media (min-width: @screen-lg-min) {
        max-width: 90%;
        margin: 0 auto;

        & > span {
            font-size: 35px;
        }

        & > h3 {
            font-size: 16px;

        }
    }
}
