@import 'shared/styles/breakpoints.less';
@import 'shared/styles/colors.less';

.wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: @primary-color;
    background-attachment: fixed;

    @media (min-width: @screen-sm-min) {
        justify-content: center;
    }


    & > form {
        width: 310px;
        padding: 24px 40px 40px;
        background-color: white;

        & > h4 {
            margin-bottom: 24px;
            font-size: 16px;
        }
    }

    button {
        width: 100%;
    }

    .logo {
        max-width: 300px;
        margin-top: 50px;

        @media (min-width: @screen-sm-min) {
            max-width: 150px;
            margin-top: 0;
            position: absolute;
            left: 30px;
            top: 30px;
        }

        @media (min-width: @screen-md-min) {
            max-width: 200px;
        }

        @media (min-width: @screen-lg-min) {
            max-width: 250px;
        }
    }

    .footer {
        color: white;
        margin-bottom: 50px;

        @media (min-width: @screen-sm-min) {
            position: absolute;
            bottom: 0px;
        }
    }
}
