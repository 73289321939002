@import '~antd/dist/antd.less';

@import './colors.less';

// @layout-sider-background: @primary-color;
@layout-body-background: #f0f2f5;

.pageHeader-global {
    padding: 0;

    & > div:first-of-type {
        padding-left: 12px;
    }

    @media (min-width: @screen-xs-min) {
        padding: 16px 12px;

        & > div:first-of-type {
            padding-left: 0;
        }
    }

    @media (min-width: @screen-md-min) {
        padding: 16px 24px;
    }
}

.card-global {
    border-width: 0;

    & > div {
        padding: 0;
    }

    @media (min-width: @screen-xs-min) {
        border-width: 1px;

        & > div {
            padding: 12px;
        }
    }

    @media (min-width: @screen-md-min) {
        & > div {
            padding: 24px;
        }
    }
}

