.title {
    display: flex;
    align-items: center;

    h2 {
        margin-bottom: 0;
        margin-right: 20px;
    }
}

.successInfo {
    & > button {
        display: block;
        margin-top: 20px;
    }
}
