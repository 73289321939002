@import 'shared/styles/breakpoints.less';

.heading {
    font-weight: 500;

}

.infoCard {
    margin: 0 auto 50px;
    text-align: center;

    @media (min-width: @screen-xl-min) {
        width: 600px;
    }
}

.actions {
    button + button {
        margin-left: 20px;
    }
}
